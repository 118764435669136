<template>
  <div class="bt-page-step-item w-full">
    <v-card class="white elevation-3 rounded-lg pa-3 mb-3">
      <v-row>
        <v-col cols="12">
          <h5 class="text-subtitle-1 font-weight-bold">Usaha Rumah Tangga</h5>
        </v-col>
        <v-col cols="12">
          <v-select
            color="black"
            autocomplete="off"
            outlined
            :items="list.sector"
            v-model="form.sektor_ekonomi"
            label="Sektor Ekonomi"
            required
            :rules="[(v) => !!v || 'Sektor Ekonomi is required']"
          />
          <v-select
            color="black"
            autocomplete="off"
            outlined
            :items="list.business"
            v-model="form.jenis_usaha"
            label="Jenis Usaha"
            required
            :rules="[(v) => !!v || 'Jenis Usaha is required']"
          />
          <v-text-field
            type="date"
            color="black"
            autocomplete="off"
            outlined
            v-model="form.mulai_usaha"
            label="Mulai Usaha"
            required
            :rules="[(v) => !!v || 'Mulai Usaha is required']"
          />
          <v-text-field
            color="black"
            autocomplete="off"
            outlined
            v-model="form.jumlah_tenaga_kerja"
            label="Jumlah Tenaga Kerja"
            required
            :rules="[(v) => !!v || 'Jumlah Tenaga Kerja is required']"
          />
          <v-textarea
            color="black"
            autocomplete="off"
            outlined
            v-model="form.keterangan_usaha"
            label="Keterangan Usaha"
            required
            :rules="[(v) => !!v || 'Keterangan Usaha is required']"
          />
          <v-text-field
            color="black"
            autocomplete="off"
            outlined
            v-model="form.lokasi"
            label="Lokasi"
            required
            :rules="[(v) => !!v || 'Lokasi is required']"
          />
          <v-text-field
            color="black"
            autocomplete="off"
            outlined
            v-model="form.omset"
            label="Omset"
            required
            :rules="[(v) => !!v || 'Omset is required']"
          />
        </v-col>
        <v-col cols="12">
          <h5 class="text-subtitle-1 font-weight-bold">
            Pengeluaran Rumah Tangga
          </h5>
        </v-col>
        <v-col cols="12">
          <v-text-field
            color="black"
            autocomplete="off"
            outlined
            v-model="form.konsumsi_beras"
            label="Konsumsi Besar"
          />
          <v-text-field
            color="black"
            autocomplete="off"
            outlined
            v-model="form.belanja_dapur"
            label="Belanja Dapur"
          />
          <v-text-field
            color="black"
            autocomplete="off"
            outlined
            v-model="form.biaya_telepon"
            label="Biaya Telepon"
          />
          <v-text-field
            color="black"
            autocomplete="off"
            outlined
            v-model="form.biaya_pendidikan"
            label="Biaya Pendidikan"
          />
          <v-text-field
            color="black"
            autocomplete="off"
            outlined
            v-model="form.biaya_lain_lain"
            label="Biaya Lain-lain"
          />
          <div>
            <label for="tanda_tangan" class="mb-2">Tanda Tangan</label>
            <VueSignaturePad
              id="tanda_tangan"
              width="100%"
              height="150px"
              ref="tanda_tangan"
              :options="{ onEnd, penColor: '#c0f' }"
              style="border: 1px solid #ccc"
            />
            <v-btn text small color="red" @click="clearTtd()">
              <v-icon>mdi-broom</v-icon> Clear
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["form", "list"],
  methods: {
    clearTtd() {
      this.$refs.tanda_tangan.undoSignature();
    },
    onEnd() {
      const { isEmpty, data } = this.$refs.tanda_tangan.saveSignature();
      this.form.tanda_tangan = isEmpty ? null : data;
    },
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>
