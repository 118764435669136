<template>
  <div class="bt-page-step-item w-full">
    <v-card class="white elevation-3 rounded-lg pa-3 mb-3">
      <h6 class="text-h6 font-weight-bold mb-4">Data Pribadi</h6>
      <v-row>
        <v-col cols="12">
          <v-select
            color="black"
            autocomplete="off"
            outlined
            :items="list.hari_transaksi"
            v-model="form.hari_transaksi"
            label="Hari Transaksi"
            required
            :rules="[(v) => !!v || 'Hari Transaksi is required']"
          />
        </v-col>
        <v-col cols="12">
          <v-select
            color="black"
            autocomplete="off"
            outlined
            :items="list.cm"
            v-model="form.cm_code"
            label="Rembug"
            required
            :rules="[(v) => !!v || 'Rembug is required']"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            type="date"
            color="black"
            autocomplete="off"
            outlined
            v-model="form.tanggal_gabung"
            label="Tanggal Gabung"
            required
            :rules="[(v) => !!v || 'Tanggal Gabung is required']"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            color="black"
            autocomplete="off"
            outlined
            v-model="form.nama"
            label="Nama Lengkap (Sesuai KTP)"
            required
            :rules="[(v) => !!v || 'Nama Lengkap (Sesuai KTP) is required']"
          />
        </v-col>
        <v-col cols="12">
          <v-file-input
            color="black"
            outlined
            label="File KTP"
            v-model="form.foto_ktp"
            accept="image/*"
            chips
            prepend-icon="mdi-camera"
            placeholder="Pilih File"
            required
            :rules="[(v) => !!v || 'File KTP is required']"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            color="black"
            autocomplete="off"
            outlined
            v-model="form.panggilan"
            label="Nama Panggilan"
            :rules="[(v) => !!v || 'Nama Panggilan is required']"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            color="black"
            autocomplete="off"
            outlined
            v-model="form.no_urut"
            label="No Urut"
            required
            :rules="[(v) => !!v || 'No Urut Kelompok is required']"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            color="black"
            autocomplete="off"
            outlined
            v-model="form.kode_kelompok"
            label="Kode Kelompok"
            required
            :rules="[(v) => !!v || 'Kode Kelompok is required']"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            color="black"
            autocomplete="off"
            outlined
            v-model="form.setoran_lwk"
            label="Setoran SDA"
            required
            :rules="[(v) => !!v || 'Setoran LWK is required']"
          />
        </v-col>
        <v-col cols="12">
          <v-select
            color="black"
            autocomplete="off"
            outlined
            :items="list.jenis_kelamin"
            v-model="form.jenis_kelamin"
            label="Jenis Kelamin"
            required
            :rules="[(v) => !!v || 'Jenis Kelamin is required']"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            color="black"
            autocomplete="off"
            outlined
            v-model="form.ibu_kandung"
            label="Ibu Kandung"
            required
            :rules="[(v) => !!v || 'Ibu Kandung is required']"
          />
        </v-col>
        <v-col cols="12">
          <v-select
            color="black"
            autocomplete="off"
            outlined
            :items="list.tempat_lahir"
            v-model="form.tempat_lahir"
            label="Tempat Lahir"
            required
            :rules="[(v) => !!v || 'Tempat Lahir is required']"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            type="date"
            color="black"
            autocomplete="off"
            outlined
            v-model="form.tanggal_lahir"
            label="Tanggal Lahir"
            required
            :rules="[(v) => !!v || 'Tanggal Lahir is required']"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            type="number"
            color="black"
            autocomplete="off"
            outlined
            v-model="form.usia"
            label="Usia"
            required
            readonly
            :rules="[(v) => (!!v && !isNaN(Number(v))) || 'Usia is required']"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            color="black"
            autocomplete="off"
            outlined
            v-model="form.no_ktp"
            label="No KTP"
            required
            :rules="[(v) => !!v || 'No KTP is required']"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            color="black"
            autocomplete="off"
            outlined
            v-model="form.no_hp"
            label="No. Telp/HP"
            required
            :rules="[(v) => !!v || 'No. Telp/HP is required']"
          />
        </v-col>
        <v-col cols="12">
          <v-textarea
            color="black"
            autocomplete="off"
            outlined
            v-model="form.alamat"
            label="Alamat (Rumah)"
            required
            :rules="[(v) => !!v || 'Alamat is required']"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            color="black"
            autocomplete="off"
            outlined
            v-model="form.rt"
            label="RT"
            :rules="[(v) => !!v || 'RT is required']"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            color="black"
            autocomplete="off"
            outlined
            v-model="form.rw"
            label="RW"
            :rules="[(v) => !!v || 'RW is required']"
          />
        </v-col>
        <v-col cols="12">
          <v-select
            color="black"
            autocomplete="off"
            outlined
            :items="list.kabupaten"
            v-model="form.kabupaten"
            label="Kabupaten/Kota (Rumah)"
          />
        </v-col>
        <v-col cols="12">
          <v-select
            color="black"
            autocomplete="off"
            outlined
            :items="list.kecamatan"
            v-model="form.kecamatan"
            label="Kecamatan (Rumah)"
          />
        </v-col>
        <v-col cols="12">
          <v-select
            color="black"
            autocomplete="off"
            outlined
            :items="list.desa"
            v-model="form.desa"
            label="Desa (Rumah)"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            color="black"
            autocomplete="off"
            outlined
            v-model="form.kodepos"
            label="Kode Pos (Rumah)"
          />
        </v-col>
        <v-col cols="12">
          <v-select
            color="black"
            autocomplete="off"
            outlined
            :items="list.pendidikan"
            v-model="form.pendidikan"
            label="Pendidikan"
            required
            :rules="[(v) => !!v || 'Pendidikan is required']"
          />
        </v-col>
        <v-col cols="12">
          <v-select
            color="black"
            autocomplete="off"
            outlined
            :items="list.pekerjaan"
            v-model="form.pekerjaan"
            label="Pekerjaan"
            required
            :rules="[(v) => !!v || 'Pekerjaan is required']"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            color="black"
            autocomplete="off"
            outlined
            v-model="form.pendapatan"
            label="Pendapatan (per bulan)"
            required
            :rules="[(v) => !!v || 'Pendapatan is required']"
          />
        </v-col>
        <v-col cols="12">
          <v-textarea
            color="black"
            autocomplete="off"
            outlined
            v-model="form.keterangan_pekerjaan"
            label="Keterangan Pekerjaan"
            required
            :rules="[(v) => !!v || 'Keterangan Pekerjaan is required']"
          />
        </v-col>
        <v-col cols="12">
          <v-select
            color="black"
            autocomplete="off"
            outlined
            :items="list.literasi_latin"
            v-model="form.literasi_latin"
            label="Literasi Baca Tulis Latin"
            :rules="[(v) => !!v || 'Literasi Baca Tulis Latin is required']"
          />
        </v-col>
        <v-col cols="12">
          <v-select
            color="black"
            autocomplete="off"
            outlined
            :items="list.literasi_arab"
            v-model="form.literasi_arab"
            label="Literasi Baca Tulis Arab"
            :rules="[(v) => !!v || 'Literasi Baca Tulis Arab is required']"
          />
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["form", "list"],
};
</script>

<style scoped>
/* Add your styles here */
</style>
