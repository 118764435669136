<template>
  <div class="bt-page-step-item w-full">
    <v-card class="white elevation-3 rounded-lg pa-3 mb-3">
      <h6 class="text-h6 font-weight-bold mb-4">Aset Rumah Tangga</h6>
      <v-row>
        <v-col cols="12">
          <h5 class="text-subtitle-1 font-weight-bold">Rumah</h5>
        </v-col>
        <v-col cols="12">
          <v-select
            color="black"
            autocomplete="off"
            outlined
            :items="list.status_rumah"
            v-model="form.status_rumah"
            label="Status Rumah"
            required
            :rules="[(v) => !!v || 'Status Rumah is required']"
          />
        </v-col>
        <v-col cols="12">
          <v-row>
            <v-col cols="6">
              <v-text-field
                color="black"
                autocomplete="off"
                outlined
                v-model="form.rekening_listrik"
                label="No Rekening Listrik"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                color="black"
                autocomplete="off"
                outlined
                v-model="form.biaya_rekening_listrik"
                label="Biaya Rekening Listrik"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-row>
            <v-col cols="6">
              <v-text-field
                color="black"
                autocomplete="off"
                outlined
                v-model="form.rekening_pdam"
                label="No Rekening PDAM"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                color="black"
                autocomplete="off"
                outlined
                v-model="form.biaya_rekening_pdam"
                label="Biaya Rekening PDAM"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-row>
            <v-col cols="6">
              <v-text-field
                color="black"
                autocomplete="off"
                outlined
                v-model="form.bpjs"
                label="No BPJS"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                color="black"
                autocomplete="off"
                outlined
                v-model="form.biaya_bpjs"
                label="Biaya BPJS"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-select
            color="black"
            autocomplete="off"
            outlined
            :items="list.house_size"
            v-model="form.ukuran"
            label="Ukuran"
            required
            :rules="[(v) => !!v || 'Ukuran is required']"
          />
          <v-select
            color="black"
            autocomplete="off"
            outlined
            :items="list.house_wall"
            v-model="form.dinding"
            label="Dinding"
            required
            :rules="[(v) => !!v || 'Dinding is required']"
          />
          <v-select
            color="black"
            autocomplete="off"
            outlined
            :items="list.house_roof"
            v-model="form.atap"
            label="Atap"
            required
            :rules="[(v) => !!v || 'Atap is required']"
          />
          <v-select
            color="black"
            autocomplete="off"
            outlined
            :items="list.house_floor"
            v-model="form.lantai"
            label="Lantai"
            required
            :rules="[(v) => !!v || 'Lantai is required']"
          />
          <v-select
            color="black"
            autocomplete="off"
            outlined
            :items="list.house_toilet"
            v-model="form.jamban"
            label="Jamban"
            required
            :rules="[(v) => !!v || 'Jamban is required']"
          />
          <v-select
            color="black"
            autocomplete="off"
            outlined
            :items="list.house_water"
            v-model="form.sumber_air"
            label="Sumber Air"
            required
            :rules="[(v) => !!v || 'Sumber Air is required']"
          />
        </v-col>
        <v-col cols="12">
          <h5 class="text-subtitle-1 font-weight-bold">Lahan</h5>
        </v-col>
        <v-col cols="12">
          <v-text-field
            color="black"
            autocomplete="off"
            outlined
            v-model="form.sawah"
            label="Sawah (meter)"
          />
          <v-text-field
            color="black"
            autocomplete="off"
            outlined
            v-model="form.kebun"
            label="Kebun (meter)"
          />
          <v-text-field
            color="black"
            autocomplete="off"
            outlined
            v-model="form.pekarangan"
            label="Pekarangan (meter)"
          />
        </v-col>
        <v-col cols="12">
          <h5 class="text-subtitle-1 font-weight-bold">Ternak</h5>
        </v-col>
        <v-col cols="12">
          <v-text-field
            color="black"
            autocomplete="off"
            outlined
            v-model="form.unggas"
            label="Unggas (ekor)"
          />
          <v-text-field
            color="black"
            autocomplete="off"
            outlined
            v-model="form.kambing"
            label="Kambing (ekor)"
          />
          <v-text-field
            color="black"
            autocomplete="off"
            outlined
            v-model="form.kerbau"
            label="Sapi/Kerbau (ekor)"
          />
        </v-col>
        <v-col cols="12">
          <h5 class="text-subtitle-1 font-weight-bold">Kendaraan</h5>
        </v-col>
        <v-col cols="12">
          <v-text-field
            color="black"
            autocomplete="off"
            outlined
            v-model="form.sepeda"
            label="Sepeda (unit)"
          />
          <v-text-field
            color="black"
            autocomplete="off"
            outlined
            v-model="form.motor"
            label="Motor (unit)"
          />
        </v-col>
        <v-col cols="12">
          <h5 class="text-subtitle-1 font-weight-bold">Elektronik</h5>
        </v-col>
        <v-col cols="12">
          <v-text-field
            color="black"
            autocomplete="off"
            outlined
            v-model="form.elektronik_radio"
            label="Radio (unit)"
          />
          <v-text-field
            color="black"
            autocomplete="off"
            outlined
            v-model="form.elektronik_vcd"
            label="VCD (unit)"
          />
          <v-text-field
            color="black"
            autocomplete="off"
            outlined
            v-model="form.elektronik_tv"
            label="TV (unit)"
          />
          <v-text-field
            color="black"
            autocomplete="off"
            outlined
            v-model="form.elektronik_kulkas"
            label="Kulkas (unit)"
          />
          <v-text-field
            color="black"
            autocomplete="off"
            outlined
            v-model="form.elektronik_mesin_cuci"
            label="Mesin Cuci (unit)"
          />
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["form", "list"],
};
</script>

<style scoped>
/* Add your styles here */
</style>
