<template>
  <div class="bt-anggota pa-5">
    <Header
      title="Registrasi Anggota Masuk"
      titleIcon="mdi-account-group"
      class="mb-4"
    />
    <v-container class="pa-0">
      <div class="bt-page-indicator d-flex justify-space-between pt-3">
        <span
          class="pa-2 pl-4 pr-4 d-flex align-center justify-center elevation-3 rounded-circle"
          :class="
            step == 1
              ? 'purple lighten-1 white--text text--darken-1'
              : 'purple lighten-5 purple--text text--darken-1'
          "
        >
          1
        </span>
        <span
          class="pa-2 pl-4 pr-4 d-flex align-center justify-center elevation-3 rounded-circle"
          :class="
            step == 2
              ? 'purple lighten-1 white--text text--darken-1'
              : 'purple lighten-5 purple--text text--darken-1'
          "
        >
          2
        </span>
        <span
          class="pa-2 pl-4 pr-4 d-flex align-center justify-center elevation-3 rounded-circle"
          :class="
            step == 3
              ? 'purple lighten-1 white--text text--darken-1'
              : 'purple lighten-5 purple--text text--darken-1'
          "
        >
          3
        </span>
        <span
          class="pa-2 pl-4 pr-4 d-flex align-center justify-center elevation-3 rounded-circle"
          :class="
            step == 4
              ? 'purple lighten-1 white--text text--darken-1'
              : 'purple lighten-5 purple--text text--darken-1'
          "
        >
          4
        </span>
      </div>

      <div class="bt-page-steps d-flex pt-5 pb-3">
        <v-form ref="form" v-model="isFormValid" lazy-validation>
          <Step1 v-show="step == 1" :form="form" :list="list" />
          <Step2 v-show="step == 2" :form="form" :list="list" />
          <Step3 v-show="step == 3" :form="form" :list="list" />
          <Step4 v-show="step == 4" :form="form" :list="list" />
        </v-form>
      </div>

      <v-row>
        <v-col cols="6" class="pb-0" v-show="step == 1">
          <router-link to="/keanggotaan/dashboard">
            <v-btn block class="purple lighten-1 white--text"> Kembali </v-btn>
          </router-link>
        </v-col>
        <v-col cols="6" class="pb-0" v-show="step > 1">
          <v-btn
            block
            class="purple lighten-1 white--text"
            @click="move(step - 1)"
          >
            Sebelumnya
          </v-btn>
        </v-col>
        <v-col cols="6" class="pb-0">
          <v-btn
            block
            class="purple lighten-1 white--text"
            @click="move(step + 1)"
            :disabled="isStepButtonDisabled"
          >
            {{ step == 4 ? "Simpan" : "Selanjutnya" }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-snackbar v-model="alert.show" :timeout="5000">
      {{ alert.msg }}
    </v-snackbar>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import services from "@/services";
import helper from "@/utils/helper";
import Toast from "@/components/Toast";
import Header from "@/components/Header";
import Step1 from "@/views/Keanggotaan/RegistrasiMasuk/Step1";
import Step2 from "@/views/Keanggotaan/RegistrasiMasuk/Step2";
import Step3 from "@/views/Keanggotaan/RegistrasiMasuk/Step3";
import Step4 from "@/views/Keanggotaan/RegistrasiMasuk/Step4";

export default {
  name: "KeanggotaanRegistrasiMasuk",
  components: {
    Toast,
    Header,
    Step1,
    Step2,
    Step3,
    Step4,
  },
  data() {
    return {
      list: {
        hari_transaksi: [
          {
            value: "1",
            text: "Senin",
          },
          {
            value: "2",
            text: "Selasa",
          },
          {
            value: "3",
            text: "Rabu",
          },
          {
            value: "4",
            text: "Kamis",
          },
        ],
        cm: [],
        jenis_kelamin: [
          {
            value: "P",
            text: "Pria",
          },
          {
            value: "W",
            text: "Wanita",
          },
        ],
        tempat_lahir: [],
        literasi_latin: [
          {
            value: "0",
            text: "Tidak",
          },
          {
            value: "1",
            text: "Ya",
          },
        ],
        literasi_arab: [
          {
            value: "0",
            text: "Tidak",
          },
          {
            value: "1",
            text: "Ya",
          },
        ],
        pendidikan: [],
        pekerjaan: [],
        status_perkawinan: [],
        pekerjaan: [],
        status_rumah: [],
        kabupaten: [],
        kecamatan: [],
        desa: [],
        sector: [],
        business: [],
        house_size: [],
        house_wall: [],
        house_roof: [],
        house_floor: [],
        house_toilet: [],
        house_water: [],
      },
      form: {
        // Pribadi
        hari_transaksi: null,
        cm_code: null,
        tanggal_gabung: null,
        nama: null,
        file_ktp: null,
        foto_ktp: null,
        panggilan: null,
        no_urut: null,
        kode_kelompok: null,
        koordinat: null,
        setoran_lwk: 1000,
        jenis_kelamin: null,
        ibu_kandung: null,
        tempat_lahir: null,
        tanggal_lahir: null,
        usia: 0,
        no_ktp: null,
        no_hp: null,
        alamat: null,
        rt: null,
        rw: null,
        desa: null,
        kecamatan: null,
        kabupaten: null,
        kodepos: null,
        pendidikan: null,
        pekerjaan: null,
        pendapatan: null,
        keterangan_pekerjaan: null,
        literasi_latin: null,
        literasi_arab: null,
        // Pasangan
        pasangan_nama: null,
        pasangan_tempat_lahir: null,
        pasangan_tanggal_lahir: null,
        pasangan_usia: null,
        pasangan_no_ktp: null,
        pasangan_no_hp: null,
        pasangan_pendidikan: null,
        pasangan_pekerjaan: null,
        pasangan_pendapatan: null,
        pasangan_keterangan_pekerjaan: null,
        pasangan_literasi_latin: null,
        pasangan_literasi_arab: null,
        jumlah_keluarga: 0,
        jumlah_tanggungan: 0,
        // Step 3
        status_rumah: null,
        rekening_listrik: null,
        biaya_rekening_listrik: 0,
        rekening_pdam: null,
        biaya_rekening_pdam: 0,
        bpjs: null,
        biaya_bpjs: 0,
        ukuran: null,
        dinding: null,
        atap: null,
        lantai: null,
        jamban: null,
        sumber_air: null,
        sawah: 0,
        kebun: 0,
        pekarangan: 0,
        unggas: 0,
        kambing: 0,
        kerbau: 0,
        sepeda: 0,
        motor: 0,
        elektronik_radio: 0,
        elektronik_vcd: 0,
        elektronik_tv: 0,
        elektronik_kulkas: 0,
        elektronik_mesin_cuci: 0,
        // Step 4
        sektor_ekonomi: 0,
        jenis_usaha: 0,
        mulai_usaha: null,
        jumlah_tenaga_kerja: 0,
        keterangan_usaha: null,
        lokasi: null,
        omset: 0,
        konsumsi_beras: 0,
        belanja_dapur: 0,
        biaya_telepon: 0,
        biaya_pendidikan: 0,
        biaya_lain_lain: 0,
        tanda_tangan: null,
      },
      step: 1,
      dateShow: false,
      isStepButtonDisabled: false,
      isFormValid: true,
      alert: {
        show: false,
        msg: "",
      },
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  watch: {
    "form.hari_transaksi": {
      handler: function (newValue, oldValue) {
        if (newValue) {
          this.getInfoRembug();
        }
      },
      immediate: true,
    },
    "form.no_ktp": {
      handler: function (newValue, oldValue) {
        if (newValue && newValue.length === 16) {
          this.getInfoCheckKTP(newValue);
        }
      },
      immediate: true,
    },
    "form.tanggal_lahir": {
      handler: function (newValue, oldValue) {
        if (newValue && newValue.length === 10) {
          this.getInfoAge("usia", "tanggal_lahir");
        }
      },
      immediate: true,
    },
    "form.pasangan_tanggal_lahir": {
      handler: function (newValue, oldValue) {
        if (newValue && newValue.length === 10) {
          this.getInfoAge("pasangan_usia", "pasangan_tanggal_lahir");
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...helper,
    async move(newStep) {
      if (newStep > 4) {
        if (!this.$refs.form.validate()) return;

        this.updateCoordinates();

        this.form.file_ktp = this.form.foto_ktp
          ? await this.fileToBase64(this.form.foto_ktp)
          : null;

        this.overlay = true;
        let payload = new FormData();

        // Pribadi
        payload.append("hari_transaksi", this.form.hari_transaksi);
        payload.append("cm_code", this.form.cm_code);
        payload.append("tanggal_gabung", this.form.tanggal_gabung);
        payload.append("nama", this.form.nama);
        payload.append("file_ktp", this.form.file_ktp);
        payload.append("panggilan", this.form.panggilan);
        payload.append("kelompok", this.form.no_urut);
        payload.append("kode_kelompok", this.form.kode_kelompok);
        payload.append("koordinat", this.form.koordinat);
        payload.append("setoran_lwk", this.form.setoran_lwk);
        payload.append("jenis_kelamin", this.form.jenis_kelamin);
        payload.append("ibu_kandung", this.form.ibu_kandung);
        payload.append("tempat_lahir", this.form.tempat_lahir);
        payload.append("tanggal_lahir", this.form.tanggal_lahir);
        payload.append("usia", this.form.usia);
        payload.append("no_ktp", this.form.no_ktp);
        payload.append("no_hp", this.form.no_hp);
        payload.append("alamat", this.form.alamat);
        payload.append("rt", this.form.rt);
        payload.append("rw", this.form.rw);
        payload.append("desa", this.form.desa);
        payload.append("kecamatan", this.form.kecamatan);
        payload.append("kabupaten", this.form.kabupaten);
        payload.append("kodepos", this.form.kodepos);
        payload.append("pendidikan", this.form.pendidikan);
        payload.append("pekerjaan", this.form.pekerjaan);
        payload.append("pendapatan", this.form.pendapatan);
        payload.append("keterangan_pekerjaan", this.form.keterangan_pekerjaan);
        payload.append("literasi_latin", this.form.literasi_latin);
        payload.append("literasi_arab", this.form.literasi_arab);
        // Pasangan
        payload.append("pasangan_nama", this.form.pasangan_nama);
        payload.append("pasangan_tempat_lahir",this.form.pasangan_tempat_lahir);
        payload.append("pasangan_tanggal_lahir",this.form.pasangan_tanggal_lahir);
        payload.append("pasangan_usia", this.form.pasangan_usia);
        payload.append("pasangan_no_ktp", this.form.pasangan_no_ktp);
        payload.append("pasangan_no_hp", this.form.pasangan_no_hp);
        payload.append("pasangan_pendidikan", this.form.pasangan_pendidikan);
        payload.append("pasangan_pekerjaan", this.form.pasangan_pekerjaan);
        payload.append("pasangan_pendapatan", this.form.pasangan_pendapatan);
        payload.append("pasangan_keterangan_pekerjaan",this.form.pasangan_keterangan_pekerjaan);
        payload.append("pasangan_literasi_latin",this.form.pasangan_literasi_latin);
        payload.append("pasangan_literasi_arab",this.form.pasangan_literasi_arab);
        payload.append("jumlah_keluarga", this.form.jumlah_keluarga);
        payload.append("jumlah_tanggungan", this.form.jumlah_tanggungan);
        // Step 3
        payload.append("status_rumah", this.form.status_rumah);
        payload.append("rekening_listrik", this.form.rekening_listrik);
        payload.append("biaya_rekening_listrik",this.form.biaya_rekening_listrik);
        payload.append("rekening_pdam", this.form.rekening_pdam);
        payload.append("biaya_rekening_pdam", this.form.biaya_rekening_pdam);
        payload.append("bpjs", this.form.bpjs);
        payload.append("biaya_bpjs", this.form.biaya_bpjs);
        payload.append("ukuran", this.form.ukuran);
        payload.append("dinding", this.form.dinding);
        payload.append("atap", this.form.atap);
        payload.append("lantai", this.form.lantai);
        payload.append("jamban", this.form.jamban);
        payload.append("sumber_air", this.form.sumber_air);
        payload.append("sawah", this.form.sawah);
        payload.append("kebun", this.form.kebun);
        payload.append("pekarangan", this.form.pekarangan);
        payload.append("unggas", this.form.unggas);
        payload.append("kambing", this.form.kambing);
        payload.append("kerbau", this.form.kerbau);
        payload.append("sepeda", this.form.sepeda);
        payload.append("motor", this.form.motor);
        payload.append("elektronik_radio", this.form.elektronik_radio);
        payload.append("elektronik_vcd", this.form.elektronik_vcd);
        payload.append("elektronik_tv", this.form.elektronik_tv);
        payload.append("elektronik_kulkas", this.form.elektronik_kulkas);
        payload.append("elektronik_mesin_cuci",this.form.elektronik_mesin_cuci);
        // Step 4
        payload.append("sektor_ekonomi", this.form.sektor_ekonomi);
        payload.append("jenis_usaha", this.form.jenis_usaha);
        payload.append("mulai_usaha", this.form.mulai_usaha);
        payload.append("jumlah_tenaga_kerja", this.form.jumlah_tenaga_kerja);
        payload.append("keterangan_usaha", this.form.keterangan_usaha);
        payload.append("lokasi", this.form.lokasi);
        payload.append("omset", this.form.omset);
        payload.append("konsumsi_beras", this.form.konsumsi_beras);
        payload.append("belanja_dapur", this.form.belanja_dapur);
        payload.append("biaya_telepon", this.form.biaya_telepon);
        payload.append("biaya_pendidikan", this.form.biaya_pendidikan);
        payload.append("biaya_lain_lain", this.form.biaya_lain_lain);
        payload.append("tanda_tangan", this.form.tanda_tangan);

        console.log("this.form", this.form);

        try {
          const req = await services.transactionRegisAnggotaMasuk(
            payload,
            this.user.token
          );
          if (req.data.status === true) {
            this.alert = {
              show: true,
              msg: req.data.msg,
            };

            setTimeout(() => {
              this.$router.push(`/keanggotaan/dashboard`);
            }, 1000);
          } else {
            this.alert = {
              show: true,
              msg: req.data.msg,
            };
          }
        } catch (error) {
          this.alert = {
            show: true,
            msg: error,
          };
        }
        this.overlay = false;
      } else {
        this.step = newStep;
        window.scrollTo(0, 0);
      }
    },
    async getInfoRembug() {
      let payload = new FormData();
      payload.append("fa_code", this.user.fa_code);
      payload.append("hari_transaksi", this.form.hari_transaksi);
      try {
        const req = await services.infoRembug(payload, this.user.token);
        if (req.status === 200) {
          if (Array.isArray(req.data.data)) {
            const cm =
              req.data.data.map((item) => {
                return {
                  value: item.cm_code,
                  text: item.cm_name,
                };
              }) || [];
            this.list.cm = cm;
          } else {
            this.alert = {
              show: true,
              msg: "Rembug tidak ditemukan",
            };
          }
        } else {
          this.alert = {
            show: true,
            msg: data.message,
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      }
    },
    async getInfoCheckKTP() {
      let payload = new FormData();
      payload.append("ktp", this.form.no_ktp);
      try {
        const req = await services.infoCheckKTP(payload, this.user.token);
        if (req.status === 200) {
          const { msg = "", status } = req.data;
          if (status) {
            this.isStepButtonDisabled = false;
          } else {
            this.isStepButtonDisabled = true;
            this.alert = {
              show: true,
              msg: msg,
            };
          }
        } else {
          this.alert = {
            show: true,
            msg: data.message,
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      }
    },
    async getInfoAge(targetField, birthdateField = "tanggal_lahir") {
      if (this.form[birthdateField] > new Date().toISOString().slice(0, 10)) {
        this.alert = {
          show: true,
          msg: "Tanggal Lahir tidak boleh lebih dari Hari ini",
        };
        this.form[birthdateField] = null;
        this.form[targetField] = null;
        return;
      }

      let payload = new FormData();
      payload.append("birthdate", this.form[birthdateField]);
      try {
        const req = await services.infoAge(payload, this.user.token);
        if (req.status === 200) {
          this.form[targetField] = req.data.data.age ?? 0;
        } else {
          this.alert = {
            show: true,
            msg: data.message,
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      }
    },
    async getInfoBirthPlace() {
      try {
        const req = await services.infoBirthPlace("", this.user.token);
        if (req.status === 200) {
          const tempat_lahir =
            req.data.data.map((item) => {
              return {
                value: item.birthplace_code,
                text: item.birthplace_name,
              };
            }) || [];
          this.list.tempat_lahir = tempat_lahir;
        } else {
          this.alert = {
            show: true,
            msg: data.message,
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      }
    },
    async getInfoPendidikan() {
      try {
        const req = await services.infoPendidikan("", this.user.token);
        if (req.status === 200) {
          const pendidikan =
            req.data.data.map((item) => {
              return {
                value: item.education_code,
                text: item.education_name,
              };
            }) || [];
          this.list.pendidikan = pendidikan;
        } else {
          this.alert = {
            show: true,
            msg: data.message,
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      }
    },
    async getInfoOccupation() {
      try {
        const req = await services.infoOccupation("", this.user.token);
        if (req.status === 200) {
          const pekerjaan =
            req.data.data.map((item) => {
              return {
                value: item.occupation_code,
                text: item.occupation_name,
              };
            }) || [];
          this.list.pekerjaan = pekerjaan;
        } else {
          this.alert = {
            show: true,
            msg: data.message,
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      }
    },
    async getInfoCity() {
      try {
        const req = await services.infoCity("", this.user.token);
        if (req.status === 200) {
          const city =
            req.data.data.map((item) => {
              return {
                value: item.city_code,
                text: item.city_name,
              };
            }) || [];
          this.list.kabupaten = city;
        } else {
          this.alert = {
            show: true,
            msg: data.message,
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      }
    },
    async getInfoDistrict() {
      try {
        const req = await services.infoDistrict("", this.user.token);
        if (req.status === 200) {
          const district =
            req.data.data.map((item) => {
              return {
                value: item.district_code,
                text: item.district_name,
              };
            }) || [];
          this.list.kecamatan = district;
        } else {
          this.alert = {
            show: true,
            msg: data.message,
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      }
    },
    async getInfoVillage() {
      try {
        const req = await services.infoVillage("", this.user.token);
        if (req.status === 200) {
          const village =
            req.data.data.map((item) => {
              return {
                value: item.village_code,
                text: item.village_name,
              };
            }) || [];
          this.list.desa = village;
        } else {
          this.alert = {
            show: true,
            msg: data.message,
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      }
    },
    async getInfoHouseStatus() {
      try {
        const req = await services.infoHouseStatus("", this.user.token);
        if (req.status === 200) {
          const house_status =
            req.data.data.map((item) => {
              return {
                value: item.house_status_code,
                text: item.house_status_name,
              };
            }) || [];
          this.list.status_rumah = house_status;
        } else {
          this.alert = {
            show: true,
            msg: data.message,
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      }
    },
    async getInfoSector() {
      try {
        const req = await services.infoSector("", this.user.token);
        if (req.status === 200) {
          const sector =
            req.data.data.map((item) => {
              return {
                value: item.sector_code,
                text: item.sector_name,
              };
            }) || [];
          this.list.sector = sector;
        } else {
          this.alert = {
            show: true,
            msg: data.message,
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      }
    },
    async getInfoBusiness() {
      try {
        const req = await services.infoBusiness("", this.user.token);
        if (req.status === 200) {
          const business =
            req.data.data.map((item) => {
              return {
                value: item.business_code,
                text: item.business_name,
              };
            }) || [];
          this.list.business = business;
        } else {
          this.alert = {
            show: true,
            msg: data.message,
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      }
    },
    async getInfoHouseWater() {
      try {
        const req = await services.infoHouseWater("", this.user.token);
        if (req.status === 200) {
          const house_water =
            req.data.data.map((item) => {
              return {
                value: item.house_water_code,
                text: item.house_water_name,
              };
            }) || [];
          this.list.house_water = house_water;
        } else {
          this.alert = {
            show: true,
            msg: data.message,
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      }
    },
    async getInfoHouseToilet() {
      try {
        const req = await services.infoHouseToilet("", this.user.token);
        if (req.status === 200) {
          const house_toilet =
            req.data.data.map((item) => {
              return {
                value: item.house_toilet_code,
                text: item.house_toilet_name,
              };
            }) || [];
          this.list.house_toilet = house_toilet;
        } else {
          this.alert = {
            show: true,
            msg: data.message,
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      }
    },
    async getInfoHouseFloor() {
      try {
        const req = await services.infoHouseFloor("", this.user.token);
        if (req.status === 200) {
          const house_floor =
            req.data.data.map((item) => {
              return {
                value: item.house_floor_code,
                text: item.house_floor_name,
              };
            }) || [];
          this.list.house_floor = house_floor;
        } else {
          this.alert = {
            show: true,
            msg: data.message,
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      }
    },
    async getInfoHouseRoof() {
      try {
        const req = await services.infoHouseRoof("", this.user.token);
        if (req.status === 200) {
          const house_roof =
            req.data.data.map((item) => {
              return {
                value: item.house_roof_code,
                text: item.house_roof_name,
              };
            }) || [];
          this.list.house_roof = house_roof;
        } else {
          this.alert = {
            show: true,
            msg: data.message,
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      }
    },
    async getInfoHouseWall() {
      try {
        const req = await services.infoHouseWall("", this.user.token);
        if (req.status === 200) {
          const house_wall =
            req.data.data.map((item) => {
              return {
                value: item.house_wall_code,
                text: item.house_wall_name,
              };
            }) || [];
          this.list.house_wall = house_wall;
        } else {
          this.alert = {
            show: true,
            msg: data.message,
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      }
    },
    async getInfoHouseSize() {
      try {
        const req = await services.infoHouseSize("", this.user.token);
        if (req.status === 200) {
          const house_size =
            req.data.data.map((item) => {
              return {
                value: item.house_size_code,
                text: item.house_size_name,
              };
            }) || [];
          this.list.house_size = house_size;
        } else {
          this.alert = {
            show: true,
            msg: data.message,
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      }
    },
    async updateCoordinates() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const coordinates = `latitude: ${position.coords.latitude}, longitude: ${position.coords.longitude}`;
            this.form.koordinat = coordinates;
          },
          (error) => {
            this.form.koordinat = null;
            this.alert = {
              show: true,
              msg: `Error: ${error.message}`,
            };
          }
        );
      } else {
        this.alert = {
          show: true,
          msg: "Geolocation is not supported by this browser.",
        };
      }
    },
  },
  mounted() {
    this.getInfoBirthPlace();
    this.getInfoPendidikan();
    this.getInfoOccupation();
    this.getInfoCity();
    this.getInfoDistrict();
    this.getInfoVillage();
    this.getInfoHouseStatus();
    this.getInfoSector();
    this.getInfoBusiness();
    this.getInfoHouseWater();
    this.getInfoHouseToilet();
    this.getInfoHouseFloor();
    this.getInfoHouseRoof();
    this.getInfoHouseWall();
    this.getInfoHouseSize();
  },
};
</script>
